import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import Spinner from 'manage-tritag/components/loading/spinner'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import EnhancedTableFilter from 'manage-tritag/components/table/enhanced-table/enhanced-table-filter'
import {
  Competition,
  QueryParams,
  useLazyGetCompetitionsQuery,
} from 'manage-tritag/services/api/endpoints/competitions'

interface HeadCell {
  disablePadding: boolean
  id: keyof Competition
  label: string
  numeric: boolean
}

const CompetitionPage = () => {
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
    currentSeason: true,
  })
  const [trigger, result] = useLazyGetCompetitionsQuery()

  const { isLoading, data: competitions } = result

  useEffect(() => {
    trigger(params)
  }, [params])

  const headCells: HeadCell[] = [
    {
      id: 'ttr_id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'association',
      numeric: false,
      disablePadding: false,
      label: 'Association',
    },
    {
      id: 'venue',
      numeric: false,
      disablePadding: false,
      label: 'Venue',
    },
  ]
  const bodyCells = ['ttr_id', 'name', 'association', 'venue']
  return (
    <Layout label1="Competitions" url="/competitions">
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        competitions && (
          <>
            <EnhancedTableFilter params={params} setParams={setParams} />
            <EnhancedTable
              tableTitle="Teams"
              uniqueIdentifier="ttr_id"
              rows={competitions.map((competition, index) => ({
                ...competition,
                id: index,
                association: competition.association
                  ? competition.association.name
                  : 'None',
                venue: competition.venue ? competition.venue.name : 'None',
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(CompetitionPage)
